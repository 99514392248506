@import '@styles/utils/index';
.top-bar-header {
  width: 100%;
  background-color: $color-light-grey;
  transition: all 0.2s ease-in-out;

  .freeze & {
    margin-bottom: -48px;
    transition: all 0.2s ease-in-out;
  }

  .sub-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 40px;

    .logo-brand-line {
      width: 25px;
      height: 25px;

      img {
        display: block;
      }
    }
  }

  .sub-list-menu {
    ul {
      display: flex;
      align-items: center;
      margin: 0;

      li {
        display: inline-block;
        vertical-align: middle;
        line-height: 14px;
        list-style: none;

        .icon-rhombus {
          margin: 0 8px;
          color: $color-dark-grey;
        }

        .icon-arrow-down {
          width: 24px;
          height: 24px;
          color: $color-black;
          cursor: pointer;
        }

        a,
        .dropdown-select {
          display: inline-block;
          vertical-align: middle;
          color: $color-black;
          text-decoration: none;
          font-size: rem(12);
          font-weight: 500;
          padding: 5px 0;
        }
      }
    }
  }
}

header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 22;
  border-bottom: 1px solid $color-light-grey;
  background-color: $color-white;

  .hamburger-icon {
    width: 26px;
    height: 18px;
    position: relative;
    transform: translateZ(0) rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $color-black;
      opacity: 1;
      left: 0;
      transform: translateZ(0) rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 7px;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 14px;
        transform-origin: left center;
      }
    }

    &.open {
      span {
        width: 24px;

        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0px;
          left: 0px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 17px;
          left: 0;
        }
      }
    }
  }

  .nav {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: $color-white;
    align-items: center;
    padding: 20px 40px;

    .header-menu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .header-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .utility {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .header-search {
        button {
          background-color: $color-white;
          display: flex;
          align-items: center;
          font-size: rem(12);
          font-weight: 500;
          padding: 8px 20px;
          border: 1px solid $color-black;
          border-radius: 8px;
          outline: none;

          .icon-search {
            margin-left: 13px;
          }
        }
      }

      .list-utility {
        ul {
          margin: 0;
          padding: 0 0 0 10px;

          li {
            display: inline-block;
            padding: 0 0 0 10px;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
