@import '@styles/utils/index';

.slider-banner {
  margin-bottom: 60px;

  img {
    width: 100%;
    height: 810px;
    object-fit: cover;
  }
  .slick-dots {
    bottom: 21px !important;

    button {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: $color-dark-grey;
      transition: all 0.2s linear;
    }

    .slick-active button {
      background-color: $color-white;
    }
  }

  .slider-item {
    padding-bottom: 56.25%;
    position: relative;

    @include mq('md', max) {
      padding-bottom: 125%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
