@import '@styles/utils/index';

.mega-menu-wrapper {
  position: absolute;
  top: 82px;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 82px);
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: transform 0.3s ease-in-out;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-left: 1px $color-grey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-grey;
    border-radius: 4px;
  }

  &.active {
    opacity: 1;
    z-index: inherit;
    pointer-events: auto;

    .mega-menu {
      .nav-sidebar {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.05s ease-in-out, transform 0.1s ease-in-out;
      }

      .image-branding {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }

      .left-category {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
      }

      .right-category {
        .type-text {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
        .product-name {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }

  .mega-menu {
    width: calc(100% + 10px);
    position: relative;

    .container {
      display: flex;
      min-height: calc(100vh - 82px);

      .mega-menu-inner {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }

    .left-siderbar-menu {
      position: relative;
      width: 330px;
      background-color: $color-light-grey;
      padding: 6px 40px 40px;
      display: flex;
      flex-direction: column;
      height: 100%;

      &::before {
        content: '';
        background-color: $color-light-grey;
        position: absolute;
        right: 100%;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
      }
    }

    .nav-sidebar {
      opacity: 0;
      transform: translateX(-50px);
      transition: none;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-top: 1px solid $color-grey;
        cursor: pointer;

        &.active {
          // font-style: italic;
          // font-weight: bold;
          // letter-spacing: -0.19px;

          .title {
            text-decoration: underline;
            text-underline-position: under;
          }

          .arrow-right {
            transform: rotate(-45deg);
          }
        }

        &:first-child {
          border-top: none;
        }

        // &:hover {
        //   font-style: italic;
        //   font-weight: bold;
        //   letter-spacing: -0.19px;
        // }

        .nav-item {
          display: flex;
          align-items: center;

          .number {
            margin-right: 20px;
            font-size: rem(14);
            font-weight: 500;
            line-height: 17px;
          }

          .title {
            font-size: rem(18);
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        .arrow-right {
          width: 23px;
          height: 22px;

          &:first-child {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .image-branding {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-top: auto;
      opacity: 0;

      &::after {
        content: '';
        display: block;
        padding-bottom: 125%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right-menu {
      position: relative;
      opacity: 1;
      width: calc(100% - 330px);
      height: auto;
      background-color: $color-white;
      padding: 0 40px;

      &::before {
        content: '';
        background-color: $color-white;
        position: absolute;
        left: 100%;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: -1;
      }

      .hide {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: none;
        transition: opacity 0.5s ease-in-out;
      }

      .show {
        opacity: 1;
        z-index: inherit;
        pointer-events: inherit;
        transition: opacity 0.5s ease-in-out;
      }

      .row-menu {
        border-top: 1px solid $color-grey;

        &:first-child {
          border-top: none;
        }
      }

      .right-category {
        padding-top: 40px;
      }

      .category {
        padding: 0 0 14px;
        max-width: 150px;
        color: $color-black;
        font-size: rem(22);
        font-weight: 600;
        text-transform: capitalize;
        line-height: 30px;
      }

      .discover-now {
        display: flex;
        align-items: center;
        font-size: rem(12);
        font-weight: 400;
        color: $color-dark-grey;
        cursor: pointer;

        .text {
          margin-right: 6px;
        }

        .arrow-right {
          width: 12px;
          height: 12px;
        }
      }

      .category-item {
        .type-text {
          font-size: 18px;
          font-weight: 600;
          color: $color-black;
          text-transform: capitalize;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .list-type {
          margin-bottom: 20px;
          
          .product-name {
            display: inline-block;
            font-size: rem(14);
            font-weight: 500;
            line-height: 17px;
            text-transform: capitalize;
            margin: 0 20px 20px 0;

            // &:hover {
            //   font-style: italic;
            //   font-weight: bold;
            //   letter-spacing: -0.3px;
            // }
          }
        }
      }

      .right-menu-inner {
        position: relative;
      }

      .banner-mega-menu {
        display: flex;
        padding-bottom: 40px;

        .wrapper {
          width: 26%;
          margin-right: 20px;

          .banner-item {
            position: relative;
            width: 100%;
            overflow: hidden;
            margin-bottom: 10px;

            &::after {
              content: '';
              display: block;
              padding-bottom: 56.25%;
            }

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .title,
          .content {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: $color-black;
            margin-bottom: 5px;
            text-transform: capitalize;
          }

          .content {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: $color-dark-grey;
          }
        }
      }
    }

    .left-category {
      opacity: 0;
      transition: none;
      padding: 40px 0;

      &.full-width {
        .category {
          max-width: 100%;
        }
      }
    }

    .right-category {
      .type-text {
        opacity: 0;
        transition: none;
      }

      .product-name {
        opacity: 0;
        transition: none;
      }
    }
  }
}
