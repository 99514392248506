@import '@styles/utils/index';

.modal-custom-lightgallery {
  position: relative;
  max-width: 100%;
  height: 100%;
  width: 100%;
  text-align: center;

  .close-popup {
    position: absolute;
    top: 3px;
    right: 3px;
  }

  .ant-modal-content {
    height: 100%;
    border-radius: 0;
    padding: 0;

    .ant-modal-body,
    .slick-slider {
      height: 100%;
    }

    .slick-list {
      height: 100%;
    }
    .slick-track {
      height: 100%;

      .slick-slide {
        height: 100%;
        overflow: hidden;

        > div,
        .img-slider {
          display: flex !important;
          justify-content: center;
          height: 100%;
          width: 100%;
          max-width: 100%;
          max-height: 100%;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }

    .slick-arrow {
      justify-content: center;
      align-items: center;
      background-color: $color-grey;
      top: 50%;
      left: 20px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      display: flex;
      opacity: 1;
      pointer-events: auto;

      &.slick-disabled {
        opacity: 0.4;
      }

      &::before {
        content: '';
        opacity: 1;
      }

      .arrow-left,
      .arrow-right {
        color: $color-black;
        width: 24px;
        height: 24px;
      }

      &.slick-next {
        left: auto;
        right: 20px;
      }
    }
    .sider-popup {
      position: relative;
      height: 100%;

      .zoom {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 1;

        .zoom-out,
        .zoom-in {
          background: $color-light-grey;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .zoom-in {
          margin-left: 5px;
        }
        .zoom-out {
          margin-right: 5px;
        }
      }
    }
  }
}
