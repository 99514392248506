a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
  }
}

.container-content {
  max-width: 1170px;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;

  @include mq ('md', max) {
    padding: 0 20px;
  }
}

.container {
  &.\--no-padding {
    padding: 0;
  }

  @include mq ('md', max) {
    padding: 0 20px;

    &.\--no-padding-mb {
      padding: 0 10px;

      .row {
        margin-left: -10px;
        margin-right: -10px;
      }
    }
  }
}

.btn-primary {
  display: inline-block;
  padding: 12px 20px;
  color: $color-black;
  text-align: center;
  font-size: rem(12);
  line-height: 24px;
  font-weight: 500;
  background-color: $color-white;
  box-shadow: none;
  white-space: nowrap;
  // text-transform: capitalize;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  border: none;

  &.\--border-line {
    border: 1px solid $color-black;
  }

  &.\--transparent {
    background-color: transparent;
  }

  &.\--bg-black {
    background-color: $color-black;
    color: $color-white;

    &:hover {
      background-color: $color-orange;
    }
  }

  &:hover {
    color: $color-white;
    background-color: $color-black;
    transition: all 0.2s ease-in-out;
    font-style: normal;
    letter-spacing: inherit;
  }
}

.dropdown-select {
  cursor: pointer;
}

.ant-dropdown {
  &.dropdown-select {
    .ant-dropdown-menu {
      padding: 10px;

      li {
        padding: 10px;
        background-color: transparent;
        font-weight: 400;
        font-size: rem(12);
        line-height: 1;
        text-transform: capitalize;
        transition: inherit;
        color: $color-black;

        &:hover {
          background-color: transparent;
          transition: inherit;
          user-select: none;
          color: $color-black;
        }
      }

      .ant-dropdown-menu-title-content > a {
        transition: inherit;
        user-select: none;
      }
    }
  }
}

.ant-dropdown-trigger {
  &.dropdown-select {
    svg {
      position: relative;
      bottom: 1px;
    }
  }
}

.ant-modal-wrap .ant-modal .ant-modal-close:hover {
  background-color: transparent;
}

// .ant-dropdown.dropdown-select .ant-dropdown-menu li:hover {
//   color: $color-dark-grey;
// }

.dflex-spacebetween {
  display: flex;
  justify-content: space-between;
}

.ant-tooltip .ant-tooltip-inner {
  color: $color-dark-grey;
}
