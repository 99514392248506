@import '@styles/utils/index';

.product-card {
  margin-bottom: 30px;

  &:hover .shopping-now {
    display: block;
  }

  &:hover .color-classification {
    display: none;

    &.no-hover {
      display: block;
    }
  }

  .card-image {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;

    &::after {
      content: '';
      display: block;
      padding-bottom: 125%;
    }

    .blur {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-black;
      opacity: 0.4;
      z-index: 1;
    }

    .title {
      position: absolute;
      font-family: 'HeadingNow-47Extrabold';
      white-space: nowrap;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      font-size: 34px;
      font-weight: 800;
      line-height: 44px;
      text-align: center;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      z-index: 1;

      .date {
        font-size: rem(14);
        font-weight: 500;
        line-height: 19px;
        font-family: 'Inter';
      }
    }
  }

  .image-product,
  .image-product-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-product-blur {
    filter: blur(4px);
  }

  .favorite {
    position: absolute;
    background-color: $color-light-grey;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    line-height: 1;

    .heart {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      color: $color-black;
      width: 24px;
      height: 24px;

      // &:hover {
      //   color: $color-orange;
      // }
    }
  }

  .product-badges {
    position: absolute;
    background-color: $color-light-grey;
    top: 20px;
    left: 20px;
    padding: 6px 10px;
    font-size: rem(12);
    font-weight: 400;
    color: $color-dark-grey;
    line-height: 15px;
  }

  .shopping-now {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
  }

  .product-info {
    min-height: 145px;

    .product-tags,
    .color-classification {
      font-size: rem(10);
      font-weight: 600;
      line-height: 12px;
      color: $color-orange;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .color-classification {
      font-weight: 400;
      color: $color-dark-grey;
      margin-bottom: 10px;
    }

    .product-tags {
      span {
        margin-right: 10px;
      }
    }

    .product-name {
      font-size: rem(12);
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 10px;
      color: $color-black;
      text-transform: capitalize;
    }

    .thumb-wrapper {
      display: none;
      margin-bottom: 5px;

      .small-images {
        display: inline-block;
        padding: 0 5px 5px 0;

        img {
          width: 49px;
          height: 49px;
          object-fit: cover;
        }
      }

      .number-images {
        font-size: 14px;
        font-weight: 500;
        color: $color-dark-grey;
      }
    }

    .product-price-wrapper {
      .product-price {
        font-size: rem(12);
        font-weight: 400;
        line-height: 15px;
        color: $color-black;
        margin-bottom: 10px;

        .currency-unit {
          text-decoration: $color-black solid underline;
          text-underline-position: under;
        }
      }

      .discount-products {
        margin-bottom: 10px;

        .discount,
        .old-price,
        .sale-off {
          font-size: rem(12);
          font-weight: 400;
          line-height: 15px;
          color: $color-black;
          margin-right: 10px;
        }

        // .discount {
        //   font-weight: 500;
        // }

        .discount {
          color: $color-black;

          .currency-unit {
            text-decoration: $color-black solid underline;
            text-underline-position: under;
          }
        }

        .old-price {
          color: $color-grey;
          text-decoration: line-through;

          .currency-unit {
            text-decoration: $color-grey solid underline;
            text-underline-position: under;
          }
        }

        .sale-off {
          color: $color-blue;
        }
      }
    }
  }

  &:hover .thumb-wrapper {
    display: block;
  }
}
