@import '@styles/utils/index';

.banner-link {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;

  &::after {
    content: '';
    display: block;
    padding-bottom: 16.6666%;

    @include mq('md', max) {
      padding-bottom: 80%;
    }
  }

  .image-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.banner-2 {
    &::after {
      padding-bottom: 33.3333%;

      @include mq('md', max) {
        padding-bottom: 80%;
      }
    }
  }
}
