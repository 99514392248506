@import '@styles/utils/index';

.wrapper-filter {
  .wrapper-item-filter {
    .list-filter {
      .status {
        .btn-status {
          display: inline-block;
          border: 1px solid $color-grey;
          color: $color-black;
          text-transform: capitalize;
          font-size: rem(12);
          font-weight: 400;
          line-height: 15px;
          border-radius: 8px;
          padding: 12px 10px;
          margin: 0px 20px 20px 0;
          cursor: pointer;
          user-select: none;

          span {
            pointer-events: none;
          }

          &.active {
            background-color: $color-black;
            color: $color-white;
          }
        }
      }

      .collection {
        .filter-item-checkbox {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 30px;
          margin-bottom: 20px;
          cursor: pointer;
          font-size: rem(12);
          font-weight: 400;
          user-select: none;

          &.hide {
            display: none;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
              background-color: $color-black;

              &::after {
                display: block;
              }
            }
          }

          .checkmark {
            position: absolute;
            top: -2px;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: $color-white;
            border: 1px solid $color-grey;
            border-radius: 4px;

            &::after {
              content: '';
              position: absolute;
              display: none;
              left: 6px;
              top: 2px;
              width: 7px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }

      .list-size {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid $color-light-grey;
        border-radius: 8px;
        margin-bottom: 20px;

        .item-size {
          width: 25%;
          font-size: rem(12);
          font-weight: 400;
          line-height: 17px;
          color: $color-black;
          padding: 17px 0;
          text-align: center;
          border-right: 1px solid $color-light-grey;
          border-top: 1px solid $color-light-grey;
          user-select: none;
          cursor: pointer;

          &:nth-child(4n) {
            border-right: none;

            &.active {
              &:after {
                width: calc(100% + 1px);
              }
            }
          }

          &:nth-child(-n + 4) {
            border-top: none;
          }

          &:first-child {
            border-radius: 8px 0 0 0;

            &:after {
              border-radius: 8px 0 0 0;
            }
          }

          &:nth-child(4) {
            border-radius: 0 8px 0 0;

            &:after {
              border-radius: 0 8px 0 0;
            }
          }

          &:nth-last-child(4) {
            border-radius: 0 0 0 8px;

            &:after {
              border-radius: 0 0 0 8px;
            }
          }

          &:last-child {
            border-radius: 0 0 8px 0;

            &:after {
              border-radius: 0 0 8px 0;
            }
          }
        }

        .active {
          position: relative;
          background-color: $color-light-grey;

          &:after {
            content: '';
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100%  + 2px);
            border: 1px solid $color-dark-grey;
            z-index: 2;
          }
        }
      }

      .colors {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .item-color {
          position: relative;
          display: inline-block;
          vertical-align: top;
          margin: 0 12px 10px 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            pointer-events: none;
          }

          &.active {
            .icon-check {
              display: block;
            }
          }
        }

        .icon-check {
          display: none;
          position: absolute;
          top: 2px;
          left: 3px;
          color: $color-white;
          pointer-events: none;
        }
      }

      .price-splitter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: rem(12);
        line-height: 30px;
        color: $color-black;
      }

      .ant-slider {
        margin: 5px 3px;

        .ant-slider-rail {
          width: calc(100% + 6px);
          left: -3px;
        }

        .ant-slider-track {
          background-color: $color-dark-grey;
        }

        .ant-slider-handle {
          &::after {
            background-color: $color-dark-grey;
            box-shadow: none;
            border-radius: 0;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
          }
        }
      }
    }

    .view-more {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: $color-dark-grey;
      margin-bottom: 22px;
      cursor: pointer;

      .plus {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .ant-collapse {
    color: black;
  }

  .custom-panel {
    .header-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;

      .title-filter {
        font-size: rem(14);
        font-weight: 500;
        line-height: 17px;
        text-transform: capitalize;
      }
    }

    &.ant-collapse-item {
      color: black;
      border-top: 1px solid $color-grey;
      padding-top: 22px;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      .ant-collapse-header,
      .ant-collapse-content-box {
        padding: 0;
        color: black;
        user-select: none;
      }

      .ant-collapse-content {
        margin: 0 -6px;
        padding: 0 6px;
      }
    }
  }

  &.hide {
    display: none;
  }
}
