@import '@styles/utils/index';

footer {
  background-color: $color-light-grey;

  .container-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .email-signup {
    width: 100%;
    margin-bottom: 40px;

    .container-content {
      display: flex;
      padding: 30px 15px;
      justify-content: space-between;
      align-items: center;

      @include mq('md', max) {
        flex-direction: column;
        text-align: center;
        padding: 40px 20px 0 20px;
      }

      .txt-register {
        width: 20%;
        font-size: rem(18);
        font-weight: 600;
        text-transform: capitalize;

        @include mq('md', max) {
          width: 100%;
          margin-bottom: 12px;
        }
      }

      .content-register {
        width: 40%;
        padding-right: 50px;
        font-size: rem(12);
        font-weight: 400;
        line-height: 24px;
        text-transform: capitalize;

        @include mq('md', max) {
          width: 100%;
          max-width: 348px;
          padding: 0;
          font-size: 14px;
          margin-bottom: 22px;
        }
      }

      .btn-primary {
        .arrow-right {
          color: white;
          margin-left: 12px;
        }
      }

      .subcribe-wrapper {
        display: flex;
        width: 40%;
        text-align: right;

        @include mq('md', max) {
          width: 100%;
          flex-direction: column;
        }

        input {
          width: 100%;
          padding: 17px 20px 18px 20px;
          border-radius: 8px;
          border: none;
          outline: none;
          font-size: rem(12);
          line-height: 15px;
          margin-right: 20px;

          &::placeholder {
            color: $color-grey;
            font-size: rem(12);
            font-weight: 400;
          }

          @include mq('md', max) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .footer-bottom {
    .container-content {
      display: block;
    }
  }

  .footer-heading {
    font-size: rem(16);
    line-height: 24px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  .footer-list {
    font-size: rem(12);
    line-height: 15px;
    font-weight: 400;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        text-decoration: none;
        color: $color-black;
        text-transform: capitalize;

        // &:hover {
        //   font-weight: bold;
        //   letter-spacing: -0.19px;
        // }

        .text {
          display: inline-block;
          vertical-align: middle;
          line-height: 24px;
        }
      }
    }

    .item-icon {
      margin-right: 10px;
      line-height: 1;
    }
  }

  .left-nav-footer {
    width: 80%;
    display: flex;

    @include mq('md', max) {
      width: 100%;
      order: 2;
      display: inline-block;
    }

    .item-nav {
      width: 25%;
      padding-right: 50px;

      @include mq('md', max) {
        width: 100%;
        padding: 20px 5px 0;
        border-bottom: 1px solid $color-grey;
      }

      &:last-child {
        padding-right: 0;

        @include mq('md', max) {
          border-bottom: none;
        }
      }
    }
  }

  .right-logo {
    width: 20%;

    @include mq('md', max) {
      width: 100%;
      order: 1;
      padding: 0 5px 40px 5px;
      border-bottom: 1px solid $color-grey;
    }
  }

  .logo-ananas {
    text-align: right;

    @include mq('md', max) {
      text-align: left;
    }

    a {
      display: inline-block;
    }
  }

  .link-list {
    padding: 10px;
  }

  .copyright {
    border-top: 1px dashed $color-black;
    text-align: center;
    padding: 10px 0;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .social-network {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 77px;
    border-top: 1px solid $color-grey;
    border-bottom: 1px solid $color-grey;

    @include mq('md', max) {
      margin-top: 20px;
      flex-direction: column;
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 25px 0;

      @include mq('md', max) {
        padding: 20px 0;
      }

      li {
        list-style: none;
        padding: 0 20px;
        line-height: 1;

        @include mq('md', max) {
          padding: 0 10px;
        }

        a {
          display: block;
          text-decoration: none;
          color: $color-black;
        }
      }
    }

    .bct {
      padding: 0 20px;

      @include mq('md', max) {
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }

  .sub-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;

    .sub-footer-left,
    .sub-footer-right {
      display: flex;
      align-items: center;
    }

    .language,
    .coppy-right,
    .terms-of-use,
    .policy-privacy {
      font-size: rem(12);
      font-weight: 400;
      margin-right: 20px;
      color: $color-dark-grey;
      text-transform: capitalize;
      line-height: 24px;
    }

    .language {
      button {
        border: none;
        background: transparent;
        margin-right: 10px;
      }

      img {
        max-height: 21px;
      }
    }

    .terms-of-use,
    .policy-privacy {
      margin-left: 20px;
      margin-right: 0;
      cursor: pointer;

      // &:hover {
      //   font-style: italic;
      //   font-weight: bold;
      //   letter-spacing: -0.19px;
      // }

      .icon-arrow-up {
        color: $color-dark-grey;
      }

      .label-dropdown {
        display: inline-block;
        line-height: 24px;
      }
    }

    .dropdown-select {
      display: inline-block;
      padding: 5px 0;
    }
  }

  // .policy-privacy.active {
  //   &, a, .dropdown-select {
  //     font-style: italic;
  //     font-weight: bold;
  //     letter-spacing: -0.19px;
  //   }
  // }
}
