@import '@styles/utils/index';

.shopping-now {
  display: none;

  .btn-with-icon {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: $color-black;
    text-align: center;
    font-size: rem(14);
    line-height: 24px;
    font-weight: 500;
    background-color: rgb(255, 255, 255, 0.5);
    box-shadow: none;
    white-space: nowrap;
    border-radius: 8px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    border: none;
    cursor: pointer;

    .text {
      margin-right: 5px;
    }
  }
}
.modal-quick-view {
  width: 100%;
  display: flex;

  .left-popup {
    position: relative;
    width: 60%;
    padding-right: 10px;

    .favorite {
      position: absolute;
      background-color: $color-light-grey;
      width: 40px;
      height: 40px;
      top: 20px;
      right: 30px;
      border-radius: 50%;
      line-height: 1;
      z-index: 1;

      .heart {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        color: $color-black;
        width: 24px;
        height: 24px;

        // &:hover {
        //   color: $color-orange;
        // }
      }
    }

    .product-badges {
      position: absolute;
      background-color: $color-light-grey;
      top: 20px;
      left: 20px;
      padding: 6px 10px;
      font-size: rem(12);
      font-weight: 400;
      color: $color-dark-grey;
      line-height: 15px;
      z-index: 1;
    }

    .slick-slider {
      .slick-arrow {
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        top: 48%;
        left: 10px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
        display: flex;
        opacity: 1;
        pointer-events: auto;

        &.slick-disabled {
          opacity: 0.4;
        }

        &::before {
          content: '';
          opacity: 1;
        }

        .arrow-left,
        .arrow-right {
          color: $color-black;
          width: 20px;
          height: 20px;
        }

        &.slick-next {
          left: auto;
          right: 10px;
        }
      }

      .img-slider {
        position: relative;
        width: 100%;
        overflow: hidden;

        &::after {
          content: '';
          display: block;
          padding-bottom: 125%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .right-popup {
    display: flex;
    width: 40%;
    padding-left: 10px;
    flex-direction: column;

    .product-tags,
    .color {
      font-size: rem(12);
      font-weight: 600;
      line-height: 15px;
      color: $color-orange;
      margin-bottom: 10px;
      text-transform: capitalize;
    }

    .title {
      max-width: 320px;
      font-size: rem(22);
      font-weight: 600;
      line-height: 27px;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    .color {
      font-weight: 400;
      margin-bottom: 50px;
      color: $color-dark-grey;
    }

    .discount-products {
      .discount,
      .old-price,
      .sale-off {
        font-size: rem(18);
        font-weight: 500;
        line-height: 22px;
        color: $color-black;
        margin-right: 10px;

        .currency-unit {
          text-decoration: $color-black solid underline;
          text-underline-position: under;
        }
      }

      .old-price {
        color: $color-grey;
        font-weight: 400;
        text-decoration: line-through;

        .currency-unit {
          text-decoration: $color-grey solid underline;
        }
      }

      .sale-off {
        color: $color-blue;
      }
    }

    .thumb-wrapper {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-grey;
      padding: 20px 0 10px 0;

      .image-item {
        img {
          width: 44px;
          height: 55px;
          object-fit: cover;
          margin: 0 10px 10px 0;
        }
      }
    }

    .list-size {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid $color-light-grey;
      border-radius: 8px;
      margin-bottom: 20px;

      .item-size {
        width: 16.6667%;
        font-size: rem(12);
        font-weight: 400;
        line-height: 17px;
        color: $color-black;
        padding: 17px 0;
        text-align: center;
        border-right: 1px solid $color-light-grey;
        border-top: 1px solid $color-light-grey;
        user-select: none;
        cursor: pointer;

        &:nth-child(6n) {
          border-right: none;

          &.active {
            &:after {
              width: calc(100% + 1px);
            }
          }
        }

        &:nth-child(-n + 6) {
          border-top: none;
        }

        &:first-child {
          border-radius: 8px 0 0 0;

          &:after {
            border-radius: 8px 0 0 0;
          }
        }

        &:nth-child(6) {
          border-radius: 0 8px 0 0;

          &:after {
            border-radius: 0 8px 0 0;
          }
        }

        &:nth-last-child(6) {
          border-radius: 0 0 0 8px;

          &:after {
            border-radius: 0 0 0 8px;
          }
        }

        &:last-child {
          border-radius: 0 0 8px 0;

          &:after {
            border-radius: 0 0 8px 0;
          }
        }
      }

      .active {
        position: relative;
        background-color: $color-light-grey;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: calc(100% + 2px);
          height: calc(100%  + 2px);
          border: 1px solid $color-dark-grey;
          z-index: 2;
        }
      }
    }

    .btn-bottom-popup {
      margin-top: auto;

      .add-to-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-black;
        color: $color-white;
        width: 100%;
        margin-bottom: 20px;
        text-transform: none;
        cursor: pointer;

        .icon-cart {
          margin-left: 5px;
        }
      }

      .view-product-details {
        background-color: $color-white;
        color: $color-black;
        width: 100%;
        border: 1px solid $color-black;
        text-transform: none;
        cursor: pointer;
      }
    }
  }
}

.popup-product .ant-modal-content {
  padding: 40px;
  border-radius: 20px;

  .close-popup {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .slick-track {
    display: flex;
    height: 100%;

    .slick-slide {
      height: auto;
      > div,
      .img-slider {
        height: 100%;
      }
    }
  }
}
