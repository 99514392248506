@import '@styles/utils/index';

.sub-slider {
  margin-bottom: 60px;
  img {
    width: 100%;
    height: 321px;
    object-fit: cover;
    border: none;
    outline: none;
  }

  .slick-initialized {
    &.slick-slider {
      overflow: hidden;
    }

    .slick-slide {
      padding: 0 15px;
    }

    .slick-list {
      margin-right: calc(-100% / 3 + 8%);
      margin-left: -15px;

      @include mq('md', max) {
        margin-right: calc(-100% / 2 + 8%);
      }
    }
  }

  .image-collection {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 9px;

    @include mq('md', max) {
      padding-bottom: 125%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title-collection {
    font-size: rem(12);
    font-weight: 500;
    line-height: 15px;
  }
}
