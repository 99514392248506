@import '@styles/utils/index';

.process {
  margin-bottom: 40px;
  .process-grid {
    background-color: $color-white;
    text-align: center;
    padding: 20px 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;

    @include mq('md', max) {
      padding: 20px 0 60px 0;
    }

    .images {
      img {
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        object-fit: cover;
      }
    }

    .title {
      font-size: rem(22);
      line-height: 26px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    .content {
      width: 100%;
      display: inline-block;
      max-width: 210px;
      font-size: rem(12);
      font-weight: 400;
      margin-bottom: 40px;
    }

    .btn-wrap {
      margin-top: auto;
    }
  }
}
