* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;

  &.freeze {
    overflow: hidden;

    .simplebar-content-wrapper {
      overflow: hidden !important;
    }
  }
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: rgba($color-grey, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: $color-grey;
  border-radius: 4px;
}

img {
  max-width: 100%;
}

.mt-60 {
  margin-top: 60px;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}
