@import '@styles/utils/index';
.is-sticky {
  .product-list .title-product {
    display: inline-block;
    font-size: 20px;
    border-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.product-list {
  .products-grid {
    padding-left: 50px;
  }

  .title-product {
    font-size: rem(22);
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    padding: 40px 0;
    color: $color-black;
    text-transform: capitalize;
    border-bottom: 1px solid $color-light-grey;
    position: sticky;
    top: 50px;
    z-index: 21;
  }

  .wall-header {
    position: sticky;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 30px;
    top: 82px;
    z-index: 20;
    background: $color-white;
    padding: 10px 15px;

    .toggle-filters {
      display: flex;
      align-items: center;
      cursor: pointer;

      .text {
        font-size: rem(14);
        font-weight: 500;
        line-height: 17px;
        color: $color-black;
        margin-right: 5px;
        text-transform: capitalize;
      }

      .icon-filters {
        width: 24px;
        height: 24px;
      }
    }

    .sorter-label {
      display: flex;

      .number-product {
        font-size: 14px;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 10px;
      }

      .sorter {
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: $color-black;
        cursor: pointer;

        .arrange {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
    }
  }
}
