.zoom-image {
  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    img {
      display: block;
      width: 100%;
      pointer-events: none;
    }

    &:hover {
      cursor: default;
      img {
        opacity: 0;
      }
    }
  }
}
