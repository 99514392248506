@import '@styles/utils/index';

.br-breadcrumb {
  width: 100%;
  background-color: $color-light-grey;
  .breadcrumb {
    max-width: 1440px;
    margin: 0 auto;

    .ant-breadcrumb {
      padding: 16px 40px;

      a {
        &:hover {
          background-color: transparent;
        }
      }

      .ant-breadcrumb-link {
        font-size: rem(12);
        font-weight: 400;
        line-height: 15px;
        text-transform: capitalize;
      }
    }
  }
}
