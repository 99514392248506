@import '@styles/utils/index';

.product-detail {
  padding: 35px 15px;

  .left-detail {
    position: sticky;
    top: calc(-100vh + 165px);
    height: 100%;

    .images-detail {
      position: relative;
      overflow: hidden;
      margin-bottom: 30px;

      .zoom-image {
        padding-bottom: 125%;
      }

      .fullscreen {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-light-grey;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        right: 20px;
        bottom: 20px;
        cursor: pointer;
      }
    }

    .video-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-top: auto;
      margin-bottom: 30px;

      &::after {
        content: '';
        display: block;
        padding-bottom: 125%;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .play-pause {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-light-grey;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
        z-index: 1;
        cursor: pointer;

        .icon-pause-play {
          pointer-events: none;

          .icon-play {
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .right-detail {
    position: sticky;
    top: 82px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .product-tags {
      font-size: rem(12);
      font-weight: 600;
      line-height: 15px;
      color: $color-orange;
      padding: 10px 0;
      text-transform: capitalize;
    }

    .name-product {
      margin-bottom: 10px;

      .name {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: rem(22);
        font-weight: 600;
        line-height: 27px;
        text-transform: capitalize;

        .product-badges {
          background-color: $color-light-grey;
          padding: 6px 10px;
          font-size: rem(12);
          font-weight: 400;
          color: $color-dark-grey;
          line-height: 15px;
          margin-left: 10px;
        }
      }
    }

    .color {
      font-size: rem(12);
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 10px;
      color: $color-dark-grey;
      text-transform: capitalize;
    }

    .review-star {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      line-height: 17px;

      .rating-star {
        display: inline-block;
        position: relative;
        margin-right: 5px;
        width: 124px;
        height: 20px;

        span {
          display: block;
          position: absolute;
          overflow: hidden;
          width: 124px;
          height: 20px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 124px;
            max-width: initial;
            height: 20px;
            overflow: hidden;
          }
        }
      }

      .number {
        font-size: rem(12);
        font-weight: 400;
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    .price {
      .discount,
      .old-price {
        font-size: rem(18);
        font-weight: 500;
        line-height: 22px;
        color: $color-black;
        margin-right: 10px;

        .currency-unit {
          text-decoration: $color-black solid underline;
          text-underline-position: under;
        }
      }

      .old-price {
        color: $color-grey;
        font-weight: 400;
        text-decoration: line-through;

        .currency-unit {
          text-decoration: $color-grey solid underline;
        }
      }

      .sale-off {
        font-size: rem(18);
        font-weight: 400;
        line-height: 22px;
        color: $color-blue;
      }
    }

    .thumb-wrapper {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $color-grey;
      padding: 20px 0 10px 0;

      .image-item {
        img {
          width: 44px;
          height: 55px;
          object-fit: cover;
          margin: 0 10px 10px 0;
        }
      }
    }

    .list-size {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid $color-light-grey;
      border-radius: 8px;
      margin-bottom: 20px;

      .item-size {
        width: 16.6667%;
        font-size: rem(12);
        font-weight: 400;
        line-height: 17px;
        color: $color-black;
        padding: 17px 0;
        text-align: center;
        border-right: 1px solid $color-light-grey;
        border-top: 1px solid $color-light-grey;
        cursor: pointer;

        &.disabled {
          color: $color-grey;
          cursor: default;
        }

        &:nth-child(6n) {
          border-right: none;

          &.active {
            &:after {
              width: calc(100% + 1px);
            }
          }
        }

        &:nth-child(-n + 6) {
          border-top: none;
        }

        &:first-child {
          border-radius: 8px 0 0 0;

          &:after {
            border-radius: 8px 0 0 0;
          }
        }

        &:nth-child(6) {
          border-radius: 0 8px 0 0;

          &:after {
            border-radius: 0 8px 0 0;
          }
        }

        &:nth-last-child(6) {
          border-radius: 0 0 0 8px;

          &:after {
            border-radius: 0 0 0 8px;
          }
        }

        &:last-child {
          border-radius: 0 0 8px 0;

          &:after {
            border-radius: 0 0 8px 0;
          }
        }
      }

      .active {
        position: relative;
        background-color: $color-light-grey;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border: 1px solid $color-dark-grey;
          z-index: 2;
        }
      }
    }

    .add-to-cart {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-black;
      color: $color-white;
      width: 100%;
      margin-bottom: 20px;
      text-transform: none;
      cursor: pointer;

      .icon-cart {
        margin-left: 5px;
      }
    }

    .favorite {
      background-color: $color-white;
      color: $color-black;
      width: 100%;
      border: 1px solid $color-black;
      text-transform: none;
      cursor: pointer;

      .icon-heart {
        margin-left: 5px;
      }
    }

    .available-store {
      padding: 20px 0;

      .title {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        .text {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          color: $color-black;
          padding: 0 5px;
          text-decoration: #0a0a0a solid underline;
          text-underline-position: under;
        }

        .icon-info {
          color: $color-grey;
        }
      }

      .content {
        font-size: rem(10);
        line-height: 24px;
        font-weight: 400;
        color: $color-dark-grey;
        text-align: justify;
      }
    }

    .detailed-information {
      padding: 20px 0;
      border-top: 1px solid $color-grey;

      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        color: $color-black;
      }

      .minus {
        cursor: pointer;
      }

      .content {
        padding: 20px 0;

        .list {
          ul {
            margin: 0;
            padding-left: 25px;
            list-style-type: disc;

            li {
              font-size: rem(12);
              font-weight: 400;
              line-height: 30px;
              color: $color-black;
              text-align: justify;
            }
          }
        }
      }

      .discover-more {
        font-size: rem(12);
        font-weight: 400;
        line-height: 15px;
        color: $color-dark-grey;
        margin-bottom: 20px;
        cursor: pointer;

        .arow-right {
          margin-left: 5px;
        }
      }
    }
  }

  .review {
    padding: 60px 105px 20px;

    .write-review {
      border: 1px solid $color-black;
      border-radius: 8px;
      background-color: $color-white;
      padding: 15px 60px;
      color: $color-black;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      cursor: pointer;
    }

    .size,
    .durability,
    .applicability {
      display: flex;
      align-items: center;
      padding: 40px;

      .text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        min-width: 225px;
      }

      .increment-bar {
        width: 100%;

        .rating-level {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-size: 400;
          line-height: 17px;
          padding: 0 5px;
        }

        .ant-slider {
          .ant-slider-rail {
            background-color: $color-grey;
          }

          .ant-slider-track {
            background-color: $color-blue;
          }

          .ant-slider-handle {
            &::after {
              box-shadow: 0 0 0 4px $color-blue;
            }
          }
        }
      }
    }
  }
}

.collapse-detail {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
