@import '@styles/utils/index';

.wrapper-mini-cart {
  position: absolute;
  max-width: 404px;
  max-height: 587px;
  width: 100%;
  top: 82px;
  right: 0;
  z-index: 1;

  .mini-cart {
    width: 100%;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    cursor: default;

    .header-mini-cart {
      padding: 20px 20px 0;
      font-size: rem(16);
      font-weight: 600;
      line-height: 19px;
      color: $color-black;
      margin-bottom: 10px;
    }

    .wrapper-container {
      max-height: 28.8vh;
      overflow-y: auto;

      .container-mini-cart {
        display: flex;
        margin: 0 20px;
        border-bottom: 1px solid $color-grey;
        padding: 10px 0;

        .left-mini-cart {
          width: 25%;

          .image {
            position: relative;
            width: 100%;
            overflow: hidden;

            &::after {
              content: '';
              display: block;
              padding-top: 125%;
            }

            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .right-mini-cart {
          width: 75%;
          padding-left: 10px;

          .product-name,
          .product-color,
          .size,
          .delete,
          .quantity {
            font-size: rem(14);
            font-weight: 500;
            line-height: 17px;
            color: $color-black;
            margin-bottom: 5px;
            text-transform: capitalize;
          }

          .product-color {
            font-weight: 400;
            color: $color-dark-grey;
          }

          .size,
          .delete,
          .quantity {
            font-weight: 400;
            color: $color-dark-grey;
            margin-bottom: 10px;
          }

          .delete,
          .quantity {
            margin-bottom: 0;
          }

          .delete {
            color: $color-grey;
            text-decoration: $color-grey solid underline;
            text-underline-position: under;
            cursor: pointer;
          }

          .discount-products {
            margin-bottom: 10px;

            .discount,
            .old-price,
            .sale-off {
              font-size: rem(14);
              font-weight: 500;
              line-height: 17px;
              color: $color-black;
              margin-right: 10px;

              .currency-unit {
                text-decoration: $color-black solid underline;
                text-underline-position: under;
              }
            }

            .old-price {
              color: $color-grey;
              font-weight: 400;
              text-decoration: line-through;

              .currency-unit {
                text-decoration: $color-grey solid underline;
              }
            }

            .sale-off {
              font-weight: 400;
              color: $color-blue;
            }
          }
        }
      }
    }

    .footer-mini-cart {
      padding: 0 20px 20px;

      .block-price {
        padding: 11px 0 0;

        .subtotal,
        .subtotal-vat {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;
        }

        .subtotal-vat {
          margin-bottom: 20px;
        }

        .label-mini-cart,
        .title-vat {
          font-size: rem(14);
          font-weight: 400;
          line-height: 17px;
          color: $color-black;
        }

        .price,
        .vat-price {
          font-size: rem(16);
          font-weight: 500;
          line-height: 19px;
          color: $color-black;
        }

        .title-vat,
        .vat-price {
          color: $color-dark-grey;
          font-size: rem(14);
        }
      }

      .payment,
      .view-cart {
        background-color: $color-black;
        color: $color-white;
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .view-cart {
        background-color: $color-white;
        color: $color-black;
        border: 1px solid $color-black;
      }

      .membership-polyci {
        font-size: rem(14);
        font-weight: 400;
        line-height: 25px;
        color: $color-dark-grey;
        text-transform: none;
        text-align: justify;

        .member-registration {
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }

    .no-product {
      text-align: center;
      padding: 30px 0;

      .image {
        padding: 6px 0 40px;
      }

      .title {
        font-size: rem(16);
        font-weight: 600;
        line-height: 19px;
        color: $color-black;
        margin-bottom: 10px;
      }

      .content {
        padding: 0 20px 15px;
        font-size: rem(14);
        font-weight: 400;
        line-height: 17px;
        color: $color-dark-grey;
      }
    }
  }
}

.cart-number-badge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 26px;
  right: 32px;
  background-color: $color-orange;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  text-align: center;
  user-select: none;

  .number {
    font-size: rem(10);
    font-weight: 500;
    line-height: 12px;
    color: $color-white;
  }
}
