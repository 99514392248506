@import '@styles/utils/index';

.modal-size-chart {
  position: relative;
  max-width: 100%;
  height: 100%;
  width: 100%;
  text-align: center;

  .ant-modal-content {
    height: 100%;
    border-radius: 0;
  }

  .imge-size-chart {
    text-align: center;
  }

  .close-popup {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.popup-size-chart {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 12px 0;

  .size-type,
  .view-size {
    font-size: rem(12);
    font-weight: 500;
    line-height: 24px;
    color: $color-black;
  }

  .view-size {
    color: $color-dark-grey;
    text-decoration: underline;
    text-underline-position: under;
    cursor: pointer;

    .size-guide {
      display: flex;
      align-items: center;

      .icon-size-chart {
        margin-left: 5px;
      }
    }
  }
}

.wrapper-size-chart {
  text-align: center;

  .title {
    font-size: rem(22);
    font-weight: 600;
    line-height: 27px;
    color: $color-black;
    padding: 40px 0;
  }

  .imge-size-chart {
    img {
      height: calc(100vh - 253px);
    }
  }
}
