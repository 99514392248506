@import '@styles/utils/index';

.banner-carousel {
  padding-bottom: 40px;

  .pa-0 {
    @include mq('md', max) {
      padding: 0;
    }
  }
  .left-banner {
    position: relative;
    width: 100%;
    overflow: hidden;

    @include mq('md', max) {
      margin-bottom: 20px;
    }

    &::after {
      content: '';
      display: block;
      padding-bottom: 66.6666%;

      @include mq('md', max) {
        padding-bottom: 125%;
      }
    }

    .image-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right-carousel {
    margin: 0 -15px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .item-carousel {
      padding: 0 15px;

      .image-item {
        padding-bottom: 125%;
        position: relative;
        margin-bottom: 10px;

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          object-fit: cover;
        }

        .overlay {
          .discover-now {
            position: absolute;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);
            transition: 0.5s ease;
            opacity: 0;

            .btn-primary {
              display: flex;
              align-items: center;
              padding: 10px 15px;
              font-size: rem(12);
              line-height: 17px;

              .text {
                padding-right: 5px;
              }
            }
          }
          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            object-fit: cover;
            transition: 0.5s ease;
            opacity: 0;
          }
        }

        &:hover .overlay .discover-now,
        &:hover .overlay img {
          opacity: 1;
        }

        .product-badges {
          position: absolute;
          background-color: $color-light-grey;
          color: $color-dark-grey;
          padding: 6px 10px;
          top: 15px;
          left: 15px;
          font-size: rem(12);
          font-weight: 400;
          line-height: 15px;
          text-transform: capitalize;
        }

        .favorite {
          position: absolute;
          background-color: $color-light-grey;
          width: 30px;
          height: 30px;
          top: 15px;
          right: 15px;
          border-radius: 50%;
          line-height: 1;

          .heart {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            color: $color-black;
            width: 18px;
            height: 18px;

            // &:hover {
            //   color: $color-orange;
            //   fill: $color-orange;
            // }

            &.active {
              color: $color-orange;
              fill: $color-orange;

              &:hover {
                color: $color-dark-grey;
                fill: $color-dark-grey;
              }
            }
          }
        }
      }

      .product-info {
        .product-tags,
        .color-classification {
          font-size: rem(10);
          font-weight: 600;
          line-height: 15px;
          color: $color-orange;
          margin-bottom: 5px;
          text-transform: capitalize;
        }

        .color-classification {
          font-weight: 400;
          color: $color-dark-grey;
          margin-bottom: 10px;
        }

        .product-tags {
          span {
            margin-right: 10px;
          }
        }

        .product-name {
          font-size: rem(12);
          font-weight: 400;
          line-height: 15px;
          margin-bottom: 5px;
          color: $color-black;
          text-transform: capitalize;
        }

        .product-price-wrapper {
          .product-price {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: $color-black;
            margin-bottom: 10px;

            .currency-unit {
              text-decoration: $color-black solid underline;
              text-underline-position: under;
            }
          }

          .discount-products {
            margin-bottom: 5px;

            .discount,
            .old-price,
            .sale-off {
              font-size: rem(12);
              font-weight: 400;
              line-height: 15px;
              color: $color-black;
              margin-right: 10px;
            }

            // .discount {
            //   font-weight: 500;
            // }

            .discount {
              color: $color-black;

              .currency-unit {
                text-decoration: $color-black solid underline;
                text-underline-position: under;
              }
            }

            .old-price {
              color: $color-grey;
              text-decoration: line-through;

              .currency-unit {
                text-decoration: $color-grey solid underline;
                text-underline-position: under;
              }
            }

            .sale-off {
              color: $color-blue;
            }
          }
        }
      }
    }

    .slick-slider {
      .slick-arrow {
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        top: 34%;
        left: 25px;
        width: 36px;
        height: 36px;
        border-radius: 100%;

        &::before {
          content: '';
          opacity: 1;
        }

        .arrow-left,
        .arrow-right {
          color: $color-black;
          width: 16px;
          height: 16px;
        }

        &.slick-next {
          left: auto;
          right: 25px;
        }
      }
    }

    .progress-border {
      background-color: $color-light-grey;
      position: relative;
      height: 5px;
      margin: auto 15px 0 15px;

      &::after {
        content: '';
        background-color: $color-light-grey;
      }

      .progress-line {
        background-color: $color-dark-grey;
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: inherit;
        transition: 0.5s ease;

        &::after {
          position: absolute;
          top: 50%;
          content: '';
          width: 9px;
          height: 9px;
          transform: translateY(-50%) rotate(45deg);
          background-color: $color-dark-grey;
          right: -4.5px;
        }
      }
    }
  }

  .slick-slider {
    &:hover .slick-arrow {
      opacity: 1;
      pointer-events: auto;
      transition: all 0.2s ease-in-out;

      &.slick-disabled {
        opacity: 0.4;
      }
    }
  }
  .slick-arrow {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
