@import '@styles/utils/index';

.category {
  .item-category {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;

    @include mq('md', max) {
      margin-bottom: 20px;
    }

    &::after {
      content: "";
      display: block;
      padding-bottom: 125%;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color-black, 0.2);
      z-index: 1;
    }

    .image-category {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .item-group {
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 50px;
      transform: translateX(-50%);
      z-index: 2;

      .title-for {
        color: $color-white;
        padding-bottom: 7px;
        font-size: rem(30);
        font-weight: 700;
        line-height: 36px;
      }

      .discover-now {
        display: inline-block;
        padding: 15px 20px;
        background-color: $color-white;
        border-radius: 8px;
        text-decoration: none;
        font-size: 15px;
        color: $color-black;

        &:hover {
          background-color: $color-black;
          color: $color-white;
        }
      }
    }
  }
}
