$color-white: #FFFFFF;
$color-black: #0A0A0A;
$color-orange: #F36C21;
$color-red: #CE1212;
$color-green: #056A37;
$color-yellow: #F5D255;
$color-light-grey: #F0F0F0;
$color-grey: #CCCCCC;
$color-dark-grey: #7D7D7D;
$color-blue: #004675;
