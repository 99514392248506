@import "../cores/grid.scss";

@mixin font-face($name, $pathNotIncludeExt, $weight: normal, $style: normal) {
  @font-face {
    font-family: $name;
    src:
      url("#{$pathNotIncludeExt}.woff") format("woff"),
      url("#{$pathNotIncludeExt}.woff2") format("woff2");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin icomoon {
  font-family: $font-icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  @include font-smooth;
}

@mixin font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@function screen($name) {
  @return map-get($grid-breakpoints, $name);
}

@mixin mq($width, $type: min) {
  @if map_has_key($grid-breakpoints, $width) {
    $width: screen($width);
  }
  // @if unitless($width) {
  //   $width: $width * 1px
  // }

  @if $type == max {
    $width: $width - 1px;
  }

  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

@mixin ip-small() {
  @media only screen and (max-width: 375px) and (max-height: 600px) {
    .iphone & {
      @content;
    }
    .chrome & {
      @content;
    }
  }
}

@mixin ip-medium() {
  @media only screen and (max-width: 375px) and (max-height: 667px) {
    .iphone & {
      @content;
    }
    .chrome & {
      @content;
    }
  }
}

@mixin ip-chrome-large() {
  @media only screen and (max-width: 414px) and (max-height: 720px) {
    .iphone.chrome & {
      @content;
    }
  }
}

@mixin ip-large() {
  @media only screen and (max-width: 450px) and (max-height: 844px) {
    .iphone & {
      @content;
    }
    .chrome & {
      @content;
    }
  }
}

@mixin android-large() {
  @media only screen and (max-width: 414px) and (max-height: 896px) {
    .chrome & {
      @content;
    }
  }
}

@mixin mm($minwidth, $maxwidth) {
  @if map_has_key($grid-breakpoints, $minwidth) {
    $minwidth: screen($minwidth);
  }
  @if map_has_key($grid-breakpoints, $maxwidth) {
    $maxwidth: screen($maxwidth) - 1px;
  }
  @media only screen and (min-width: $minwidth) and (max-width: $maxwidth) {
    @content;
  }
}

@mixin no-touch {
  html.no-touch & {
    @content;
  }
}

@mixin hover($parentSelector: false) {
  @if $parentSelector {
    #{$parentSelector}:hover & {
      @include no-touch {
        @content;
      }
    }
  } @else {
    @include no-touch {
      &:hover {
        @content;
      }
    }
  }
}

@mixin size($width, $height: $width) {
  @if unitless($width) {
    $width: rem($width * 1px)
  }

  @if unitless($height) {
    $height: rem($height * 1px)
  }

  width: $width;
  height: $height;
}

@mixin text-overflow {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}


@mixin overflow-y {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@mixin overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin hide-text-with-line($line: 4) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  white-space: initial;
}

@for $i from 10 through 40 {
  .font-#{$i} {
    font-size: $i * 1px;
  }
}
