$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
);

$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1134px,
  xl: 1440px,
);

$grid-gutter-width: 30px;
