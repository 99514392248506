#loader-wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  margin: auto;
  z-index: 99999;
  transition: opacity 0.5s ease-in-out;
  background-color: $color-white;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: $color-black;
  }

  &.loaded {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &.loading-hide {
    pointer-events: none;
    z-index: -1;
  }
}

.loader {
  $loader-size: 4;
  $animation-duration: 2s;
  $animation-speed: 10;
  $animation-scale: 1.4;
  $loader-color: $color-white;
  $loader-text-color: $color-white;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: calc($loader-size * 10px);
  width: calc($loader-size * 20px);
  box-sizing: border-box;
  z-index: 3;

  .loader-item {
    position: relative;
    float: left;
    height: calc($loader-size * 10px);
    width: calc($loader-size * 1px);
    margin: 0 calc($loader-size/2) * 1px;
    background-color: $loader-color;

    @for $i from 1 through 10 {
      &:nth-child(#{$i}) {
        animation: loader-item-#{$i} $animation-duration linear infinite;
      }
    }
  }

  &:after {
    font-family: $font-family-default;
    content: 'Loading...';
    font-size: ($loader-size * 4px);
    color: $loader-text-color;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: calc($loader-size * -8px);
    margin: auto;
  }

  @for $i from 0 through 9 {
    @keyframes loader-item-#{$i+1} {
      #{$i+1 + $i * 5 * 1%} {
        transform: scaleY(1);
      }

      #{$i + 1 + $i * 5 + $animation-speed * 1%} {
        transform: scaleY($animation-scale);
      }

      #{$i + 1 + $i * 5 + $animation-speed * 2 * 1%} {
        transform: scaleY(1);
      }

      100% {
        transform: scaleY(1);
      }
    }
  }
}
