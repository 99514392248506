@import '@styles/utils/index';

.carousel-slider {
  max-width: 1080px;
  margin: 0 auto 60px;

  .title-carousel {
    font-size: rem(24);
    color: $color-black;
    font-weight: 700;
    line-height: 38.73px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 40px;
  }

  .item-carousel {
    padding: 0 15px;

    @include mq('md', max) {
      padding: 0 10px;
    }

    .image-item {
      padding-bottom: 125%;
      position: relative;
      margin-bottom: 10px;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
      }

      .overlay {
        .discover-now {
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          transition: 0.5s ease;
          opacity: 0;

          .btn-primary {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            font-size: rem(12);

            .text {
              padding-right: 5px;
            }
          }
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          object-fit: cover;
          transition: 0.5s ease;
          opacity: 0;
        }
      }

      &:hover .overlay .discover-now,
      &:hover .overlay img {
        opacity: 1;
      }

      .product-badges {
        position: absolute;
        background-color: #f0f0f0;
        top: 20px;
        left: 20px;
        padding: 6px 10px;
        font-size: rem(12);
        font-weight: 400;
        color: $color-dark-grey;
        line-height: 15px;
      }

      .favorite {
        position: absolute;
        background-color: $color-light-grey;
        width: 40px;
        height: 40px;
        top: 20px;
        right: 20px;
        border-radius: 50%;
        line-height: 1;

        .heart {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          color: $color-black;
          width: 24px;
          height: 24px;

          // &:hover {
          //   color: $color-orange;
          //   fill: $color-orange;
          // }

          &.active {
            color: $color-orange;
            fill: $color-orange;

            &:hover {
              color: $color-dark-grey;
              fill: $color-dark-grey;
            }
          }
        }
      }
    }

    .product-info {
      .product-tags,
      .color-classification {
        font-size: rem(10);
        font-weight: 600;
        line-height: 15px;
        color: $color-orange;
        margin-bottom: 5px;
        text-transform: capitalize;
      }

      .color-classification {
        font-weight: 400;
        color: $color-dark-grey;
        margin-bottom: 0;
      }

      .product-tags {
        span {
          margin-right: 10px;
        }
      }

      .product-name {
        font-size: rem(12);
        font-weight: 400;
        line-height: 15px;
        margin-bottom: 5px;
        color: $color-black;
        text-transform: capitalize;
      }

      .product-price-wrapper {
        .product-price {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          color: $color-black;
          margin-bottom: 10px;

          .currency-unit {
            text-decoration: $color-black solid underline;
            text-underline-position: under;
          }
        }

        .discount-products {
          margin-bottom: 5px;

          .discount,
          .old-price,
          .sale-off {
            font-size: rem(12);
            font-weight: 400;
            line-height: 15px;
            color: $color-black;
            margin-right: 10px;
          }

          // .discount {
          //   font-weight: 500;
          // }

          .discount {
            color: $color-black;

            .currency-unit {
              text-decoration: $color-black solid underline;
              text-underline-position: under;
            }
          }

          .old-price {
            color: $color-grey;
            text-decoration: line-through;

            .currency-unit {
              text-decoration: $color-grey solid underline;
              text-underline-position: under;
            }
          }

          .sale-off {
            color: $color-blue;
          }
        }
      }
    }
  }

  .slick-slider {
    .slick-arrow {
      justify-content: center;
      align-items: center;
      background-color: $color-grey;
      top: 38%;
      left: -10%;
      display: flex;
      width: 60px;
      height: 60px;
      border-radius: 100%;
      opacity: 1;

      &::before {
        content: '';
        opacity: 1;
      }

      &.slick-disabled {
        pointer-events: none;
        opacity: 0.35;
      }

      .arrow-left,
      .arrow-right {
        color: $color-black;
        width: 24px;
        height: 24px;
      }

      &.slick-next {
        left: auto;
        right: -10%;
      }
    }

    .slick-list {
      @include mq('md', max) {
        margin-left: -10px;
      }
    }
  }
}
